import { useNavigate } from 'react-router-dom';

function TabLink(props) {
  let navigate = useNavigate();

  const { label, link, isActiveTab, onClickTab } = props;

  const handleClick = () => {
    onClickTab(label);
    navigate(link);
  }

  return (
    <div className={`px-3 text-2xl hover:bg-slate-300 cursor-pointer bg-gray-400 border-r-4 border-primgreen ${isActiveTab ? 'bg-slate-500' : 'bg-slate-200'}`} onClick={() => handleClick() }>{ label }</div>
  )

}

export default TabLink;
