import { Component, useRef } from 'react';

import { useResize } from '../../../../services/ElementSizeService';
import VideoPlaySummaryMatch from './VideoPlaySummaryMatch';

function matchWidth(containerWidth, { start_time, end_time }, { duration }) {
  const percentage = duration / (end_time - start_time);

  return Math.round(percentage * containerWidth);
}

function leftPosition(containerWidth, { start_time, end_time }, { game_start_time }) {
  const percentage = (game_start_time - start_time) / (end_time - start_time);

  return Math.round(percentage * containerWidth);
}

function VideoPlaySummary(props) {
  const componentRef = useRef();
  const { width } = useResize(componentRef);

  const { height, video } = props;

  return (
    <div ref={componentRef} style={{ height }} className="relative bg-slate-300 w-full">
    { video.matches.sort((a, b) => a.game_start_time - b.game_start_time).map(match => (
      <VideoPlaySummaryMatch
        key={match.id}
        height={height}
        left={leftPosition(width, video, match)}
        matchId={match.id}
        tileImage={match.champion.tile_image}
        width={matchWidth(width, video, match)}
      />
    )) } 
    </div>
  )
}

export default VideoPlaySummary;
