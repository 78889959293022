import { Component, useRef } from 'react';

import './PercentageBarDisplay.css';

import { useResize } from '../../../services/ElementSizeService';

function percentageWidth(width, percentage) {
    return Math.round(percentage * width);
}

function PercentageBarDisplay(props) {
  const componentRef = useRef();
  const { width } = useResize(componentRef);

  const { height, percentage } = props;

  return (
    <div ref={componentRef} style={{ height }} className="percentage-container bg-primblue w-full">
      <div style={{ height, width: percentageWidth(width, percentage) }}className="relative bg-primgreen top-0 left-0 percentage" /> 
    </div>
  )
}

export default PercentageBarDisplay;
