import { Component, useRef } from 'react';
import './BorderedTitleContainer.css';

import { useResize } from '../../../services/ElementSizeService';

const renderTabs = tabs => {
  if (!tabs || !tabs.length) {
    return;
  }

  return (
    <div className="flex tabs-container absolute right-10">
    { tabs.map(tab => (
      <div onClick={tab.onClick} className="text-2xl hover:bg-white cursor-pointer bg-gray-400 px-1 border-x-4 border-b-4 border-primgreen">{ tab }</div>
    )) }
    </div>
  )
};

const BorderedTitleContainer = ({ children, tabs, title }) =>{
  const componentRef = useRef()
  const { width } = useResize(componentRef)

  return (
    <div className="relative">
      <div style={{ width }}className="border-cover bg-white absolute top-0 left-10 h-[4px]"></div>
      <div ref={componentRef} className="hovered-text text-primblue text-2xl bg-transparent absolute left-10">{ title }</div>
      { renderTabs(tabs) }
      <div className="flex rounded-md border-4 border-primgreen h-full flex-col">
        { children }
      </div>
    </div>
  )
}

export default BorderedTitleContainer;
