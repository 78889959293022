import { useQueryClient } from '@tanstack/react-query';

const LOCAL_STORAGE_HEADERS_KEY = "champ-vods-user-headers";
const LOCAL_STORAGE_USER = "champs-vods-user";

const AUTH_DATA_MAP = {
  'auth_token': 'authToken',
  'client_id': 'clientId',
  'expiry': 'expiry',
  'uid': 'uid'
};

const AUTH_HEADERS_MAP = {
  'authToken': 'access-token',
  'clientId': 'client',
  'expiry': 'expiry',
  'uid': 'uid'
}

export function getAuthHeaders() {
  return new Promise((resolve) => {
    const localStorageHeaders = localStorage.getItem(LOCAL_STORAGE_HEADERS_KEY);
    if (!localStorageHeaders) {
      resolve({});
    }

    const headers = JSON.parse(localStorageHeaders);
    const authHeaders = Object.keys(AUTH_HEADERS_MAP).reduce((obj, key) => {
      obj[AUTH_HEADERS_MAP[key]] = headers[key];
      return obj;
    }, {});

    resolve(authHeaders);
  });
}

export function initUser() {
  const userJson = localStorage.getItem(LOCAL_STORAGE_USER);
  try {
    const user = JSON.parse(userJson);
    return user;
  } catch {
    return null;
  }
}

export function logout(queryClient) {
  return new Promise((resolve) => {
    localStorage.removeItem(LOCAL_STORAGE_USER);
    localStorage.removeItem(LOCAL_STORAGE_HEADERS_KEY);
    resolve();
  });
}

export function setAuthDataFromHeaders(response) {
  return new Promise((resolve) => {
    const authData = Object.keys(AUTH_HEADERS_MAP).reduce((obj, key) => {
      const header = response.headers.get(AUTH_HEADERS_MAP[key]);
      if (header) {
        obj[key] = header;
      }
      return obj;
    }, {});

    if (authData.authToken) {
      localStorage.setItem(LOCAL_STORAGE_HEADERS_KEY, JSON.stringify(authData));
    }

    resolve(response);
  });
}

export function setAuthDataFromLogin(data) {
  return new Promise((resolve) => {
    const authData = Object.keys(AUTH_DATA_MAP).reduce((obj, key) => {
      obj[AUTH_DATA_MAP[key]] = data[key]
      return obj;
    }, {});

    localStorage.setItem(LOCAL_STORAGE_HEADERS_KEY, JSON.stringify(authData));

    resolve(authData);
  })
}

export function setUser(user) {
  return new Promise((resolve) => {
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
    resolve(user);
  })
}
