import { Component } from 'react';
import { Link } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';

import './ChampSelector.css';

import { BorderedTitleContainer, TiledImage } from '../../shared';

class  ChampSelector extends Component {
  render() {
    const { champions, isLoading } = this.props;

    if (isLoading || !champions || !champions.length) {
      return <h1>Loading!</h1>
    }

    return (
      <BorderedTitleContainer title={'Champions'}>
        <div className="flex flex-wrap p-4">
          {
            champions.map(champ => (
              <Tooltip title={champ.name} arrow key={champ.id}>
                <Link to={`/champions/${champ.id}`}>
                  <TiledImage disabled={champ.disabled} tileImage={champ.tile_image} />
                </Link>
              </Tooltip>
            ))
          }
        </div>
      </BorderedTitleContainer>
    )
  }
}

export default ChampSelector;
