import {
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useAxios } from '../contexts/AxiosContext';

export function useSummoners(streamerId) {
  const axios = useAxios();

  return useQuery(["streamers", streamerId, "summoners"], async () => {
    const { data } = await axios.get(`/api/streamers/${streamerId}/summoners`);

    return data;
  });

}
