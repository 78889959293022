import { useState } from 'react';

import { Button, Input } from '@mantine/core';

import { useAxios } from '../../../contexts/AxiosContext';
import { createStreamerSummoner } from '../../../services/StreamerApiService';

function StreamerForm() {
  const axios = useAxios();
  const [opLink, setOpLink] = useState('opLink');
  const [twitchLink, setTwitchLink] = useState('twitchLink');

  return (
    <div className="p-4">
      <Input
        onChange={(event) => setTwitchLink(event.currentTarget.value)}
        placeholder="Twitch Link" />
      <Input
        onChange={(event) => setOpLink(event.currentTarget.value)}
        placeholder="OP Link" />
      <Button
        fullWidth
        onClick={
          async() => createStreamerSummoner(axios, twitchLink, opLink)
        }>Submit New Summoner and/or Streamer</Button>
    </div>
  )
}

export default StreamerForm;
