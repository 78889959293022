import { useContext, useEffect } from 'react';

import { useAxios } from '../../../contexts/AxiosContext';
import { useUser } from '../../../services/UserApiService';

import { markAsViewed } from '../../../services/MatchApiService';

import { BorderedTitleContainer } from '../../shared';

import { useContainerHeight } from '../../../layouts/ScreenSizeMaxLayout';

function VideoPlayer(props) {
  const axios = useAxios();
  const { data: user = {} } = useUser();
  const { isLoading, match } = props;

  useEffect(() => {
    if (user && user.id) {
      let timeout = setTimeout(() => markAsViewed(axios, match.id), 10000)
    
      return () => {
        clearTimeout(timeout);
      }
    }
  }, [user])

  const { containerHeight } = useContainerHeight();

  if (isLoading || !match) {
    return <h1>Loading!</h1>
  }


  return (
    <BorderedTitleContainer title={'Video'}>
      <iframe
        style={{ height: `${containerHeight}px`}}
        className="w-full p-1"
        src={match.embedded_url}
        allowFullScreen={true}>
      </iframe>
    </BorderedTitleContainer>
  )
}

export default VideoPlayer;

