import { Component } from 'react';

import { TiledImage } from '../../shared';

function SummonerListRow(props) {
  const {
    name,
    profileIconId,
  } = props;

  return (
    <tr>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <TiledImage tileImage={`/assets/profileicons/${profileIconId}.png`} height={'3rem'} width={'3rem'} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{name}</div>
          </div>
        </div>
      </td>
    </tr>
  )
}

export default SummonerListRow;
