import { Component } from 'react';

import VideoListRow from '../videoListRow/VideoListRow';

class VideoList extends Component {
  render() {
    const { videos, isLoading, showStreamer = true } = this.props;

    if (isLoading || !videos || !videos.length) {
      return <h1>Loading!</h1>
    }

    return (
      <table className="table-fixed min-w-full divide-y divide-gray-200">
        <thead>
              <tr>
                { showStreamer &&
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Streamer
                </th>
                }
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Duration
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Play Breakdown
                </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {
            videos.map((vid) => (
              <VideoListRow
                {...vid}
                id={vid.id}
                key={vid.id}
                showStreamer={showStreamer} />
            ))
          }
        </tbody>
      </table>
      
    )
  }
}

export default VideoList;
