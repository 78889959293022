import { Outlet, useNavigate } from 'react-router-dom';

import {
  BorderedTitleContainer,
  LoadingPlaceholder,
  TabbedContainer,
  TiledImage
} from '../components/shared';
import { useUser } from '../services/UserApiService';


function ProfilePage() {
  const navigate = useNavigate();
  const { data: user, isError, isLoading } = useUser();
  // If user not logged in, redirect to login page
  if (isLoading || !user) {
    return (
      <LoadingPlaceholder />
    )
  }

  const tabsForUser = (user) => {
    if (user.admin) {
      return [
        { label: 'Follows', link: `/profile` },
        { label: 'Streamer Creation', link: '/profile/streamer_creation' }
      ];
    } else {
      return [{ label: 'Follows', link: `/profile/follows` }];
    }
  }

  return (
    <BorderedTitleContainer title={'Profile'}>
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex flex-col p-2">
          <div className="flex items-center w-full">
            <TiledImage height={'4rem'} width={'4rem'} tileImage={user.image} />
            <div className="flex flex-col w-full">
              <div>{ user.name }</div>
            </div>
          </div>
          <div>
          </div>
        </div>
        <div className="p-2">
        </div>
        <div className="col-span-2">
          <TabbedContainer tabs={tabsForUser(user)}>
            <Outlet />
          </TabbedContainer>
        </div>
      </div>
    </BorderedTitleContainer>
  )
}

export default ProfilePage;
