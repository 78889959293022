import { Component } from 'react';

import StreamerNavListItem from './StreamerNavListItem';

class StreamerNavList extends Component {
  render() {
    const { isLoading, streamers } = this.props;

    if (isLoading || !streamers || !streamers.length) {
      return <span>Loading</span>
    }

    return (
      <ul className="flex flex-col w-full">
          <li className="px-4"><span>Popular Streamers</span></li> 
          {
            streamers.map(streamer => (
              <StreamerNavListItem key={streamer.id} streamer={streamer} />
            ))
          }
      </ul>
    )
  }
}

export default StreamerNavList;

