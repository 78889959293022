import { Component, useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { SparklesIcon } from '@heroicons/react/24/solid';

import { BorderedTitleContainer, Button } from '../shared';

import { setAuthDataFromLogin } from '../../services/UserAuthService';
import { useUser } from '../../services/UserApiService';

function LoginComponent() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [externalPopup, setExternalPopup] = useState(null);

  const reasonsToLogin = [
    "Easily find your favorite streamers.",
    "Keep track of which matches you've already watched.",
  ];

  const openLoginPopup = () => {
    const width = 500;
    const height = 400;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const title = 'Login with Twitch';
    const hostname = window.location.hostname;
    const popup = window.open(`/api/auth/twitch?auth_origin_url=https%3A%2F%2F${hostname}%2Fauth%2Fcallback&blank=true`, title, `width=${width},height=${height},left=${left},top=${top}`);
    setExternalPopup(popup);
  };

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }

      window.onmessage = async event => {
        if (event.data.auth_token) {
          await setAuthDataFromLogin(event.data);
          await queryClient.invalidateQueries({ queryKey: ['user'] })
          navigate("/");
        }
      }

      externalPopup.postMessage('requestCredentials', '*') 
    }, 500)
  }, [externalPopup])

  return (
    <div className="h-full flex justify-center items-center">
      <div className="max-w-screen-sm min-w-[300px]">
        <BorderedTitleContainer>
          <div className="flex flex-col justify-center items-center p-2 w-full">
            <h1 className="p-8">Login With Twitch</h1>
            <img src={'/assets/other/twitch.png'} style={{ height: '2rem' }} />
            <ul>
              {
                reasonsToLogin.map((reason) => (
                <li key={reason} className="flex gap-x-3"><SparklesIcon className="h-6 w-5 flex-none" />{ reason }</li>
                ))
              }
            </ul>
            <button className="relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => openLoginPopup()}>
              Sign in with Twitch
            </button>
          </div>
        </BorderedTitleContainer>
      </div>
    </div>
  )
}

export default LoginComponent;
