import { useParams } from "react-router-dom";
import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { useAxios } from '../../../contexts/AxiosContext';
import { streamerMatchesKey, streamerMatchesFn, useStreamerVideos } from '../../../services/StreamerApiService';

import VideoList from '../../videos/videoList/VideoList';

function StreamerVideosTab() {
  const queryClient = useQueryClient();
  const axios = useAxios();
  let params = useParams();
  const { data: vods, isLoading } = useStreamerVideos(params.streamerId);

  useEffect(() => {
    queryClient.prefetchQuery(
      streamerMatchesKey(params.streamerId),
      () => streamerMatchesFn(axios, params.streamerId)
    )
  })

  return (
    <VideoList
      isLoading={isLoading}
      videos={vods}
      showStreamer={false}
    />
  )
}

export default StreamerVideosTab;
