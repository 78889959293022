import { Component } from 'react';
import { Outlet } from 'react-router-dom';

import { TabbedContainer, TiledImage } from '../../shared';

import StreamerChampPlayRate from '../streamerChampPlayRate/StreamerChampPlayRate';
import MatchList from '../../matches/matchList/MatchList';

class StreamerProfileInfo extends Component {
  tabsForUser() {
    const { streamer: { id } } = this.props;
    const { user } = this.props;
    const tabs = [
      { label: 'Matches', link: `/streamers/${id}` },
      { label: 'Vods', link: `/streamers/${id}/vods` }
    ];
    if (user && user.admin) {
      return tabs.concat({ label: 'Admin', link: `/streamers/${id}/admin` });
    }

    return tabs;
  }

  render() {
    const {
      streamer: {
        id,
        champion_play_rate,
        display_name,
        description,
        login,
        profile_image_url
      },
      user
    } = this.props;

    return (
      <div className="grid grid-cols-2 gap-4 w-full">
        <div className="flex flex-col p-2">
          <div className="flex items-center w-full">
            <TiledImage height={'10rem'} width={'10rem'} tileImage={profile_image_url} />
            <div className="flex justify-center w-full">
              <a href={`https://twitch.tv/${login}`}>
                <img src={'/assets/other/twitch.png'} style={{ height: '2rem' }} />
              </a>
            </div>
          </div>
          <div>
            { description }
          </div>
        </div>
        <div className="p-2">
          <StreamerChampPlayRate champions={champion_play_rate} />
        </div>
        <div className="col-span-2">
          <TabbedContainer tabs={this.tabsForUser()}>
            <Outlet />
          </TabbedContainer>
        </div>
      </div>
    )
  }
}

export default StreamerProfileInfo;
