import { useParams } from "react-router-dom";

import { useMatch } from '../services/MatchApiService';

import VideoPlayer from '../components/videos/videoPlayer/VideoPlayer';

function VideoPage() {
  let params = useParams();
  const { data: match, isLoading } = useMatch(params.matchId);

  return (
    <VideoPlayer isLoading={isLoading} match={{ id: params.matchId, ...match }} />
  )
}

export default VideoPage;
