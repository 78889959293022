import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  STREAMER_NAME_IMAGE,
  MOST_PLAYED_CHAMP,
  MOST_PLAYED_ROLE
} from '../constants';

import StreamerNameImageCell from '../streamerNameImageCell/StreamerNameImageCell';
import StreamerMostPlayedCell from '../streamerMostPlayedCell/StreamerMostPlayedCell';
import { TiledImage } from '../../shared';

const COLUMN_TO_RENDER = {
  [STREAMER_NAME_IMAGE]: (props) => {
    return <StreamerNameImageCell {...props} key={`${props.id}_${STREAMER_NAME_IMAGE}`}/>;
  },
  [MOST_PLAYED_CHAMP]: (props) => {
    const { most_played_role: role } = props;
    return (
      <td className="px-6 py-4 whitespace-nowrap" key={`${props.id}_${MOST_PLAYED_CHAMP}`}>
        <TiledImage tileImage={`/assets/positions/${role.toLowerCase()}.png`} height={'3rem'} width={'3rem'} />
      </td>
    )
  },
  [MOST_PLAYED_ROLE]: (props) => {
    const { champion_play_rate } = props;
    return (
      <td className="" key={`${props.id}_${MOST_PLAYED_ROLE}`}>
        <StreamerMostPlayedCell mostPlayedChamps={champion_play_rate} />
      </td>
    )
  }
};

function StreamerListRow(props)  {
  const { columns, id } = props;
  const navigate = useNavigate();

  return (
    <tr className="hover:bg-slate-400" onClick={() => navigate(`/streamers/${id}`)}>
      {
        columns.map(c => (
          COLUMN_TO_RENDER[c](props)
        )) 
      } 
    </tr>
  ) 
}

export default StreamerListRow;
