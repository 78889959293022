import { 
  useQuery,
  useInfiniteQuery,
} from '@tanstack/react-query';

import { useAxios } from '../contexts/AxiosContext';

import {
  getNextPageParamConfig,
  useInfiniteQueryPaginated
} from './ApiService';

export function useChampions() {
  const axios = useAxios();

  return useQuery(["champions"], async () => {
    const { data } = await axios.get(
      "/api/champions"
    );

    return data;
  });
}

export function useChampionStreamerVideos(championId, streamerId) {
  const axios = useAxios();

  return useInfiniteQuery(
    ["champions", championId, "streamers", streamerId],
    useInfiniteQueryPaginated(axios, `/api/champions/${championId}/streamers/${streamerId}/matches`),
    getNextPageParamConfig
  )
}

export function useChampionVideos(championId) {
  const axios = useAxios();

  return useInfiniteQuery(
    ["championVideos", championId],
    useInfiniteQueryPaginated(axios, `/api/champions/${championId}/matches`),
    getNextPageParamConfig
  );
}

export function usePopularChampionStreamers(championId) {
  const axios = useAxios();

  return useQuery(["popularChampionStreamers", championId], async () => {
    const { data } = await axios.get(
      `/api/champions/${championId}/streamers/popular`
    );

    return data;
  });

}
