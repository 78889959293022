import { Component } from 'react';

class Button extends Component {
  render() {
    const { label, onClick } = this.props;

    return (
      <div className="text-primblue rounded-md border-2 border-primgreen" onClick={onClick}>{ label }</div>
    )
  }
}

export default Button;

