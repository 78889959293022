import { useRef } from 'react';
import { Link } from 'react-router-dom';
import { Divider } from '@mantine/core';
import TiledImage from '../tiledImage/TiledImage';
import { useResize } from '../../../services/ElementSizeService';
import { convertRemToPixels } from '../../../services/Measurements';

import './GenericColumnedCell.css';

const BORDER_WIDTH_PX = 4;
const IMAGE_PADDING_REM = 0.25;
const TILE_IMAGE_WIDTH_REM = 4;
const MIN_COL_WIDTH_INCLUDE_ALL = 350;

function GenericColumnedCell(props) {
  const componentRef = useRef()
  const { width } = useResize(componentRef);

  const { columns, link } = props;

  const imageCount = columns.filter(c => c.type == 'image').length;
  
  const imageWidth = convertRemToPixels(TILE_IMAGE_WIDTH_REM) +
        convertRemToPixels(IMAGE_PADDING_REM * 2) +
        BORDER_WIDTH_PX;


  const columnWidth = (column, textColsCount) => {
    if (column.type == 'image') {
      return imageWidth;  
    }

    return (width - (imageCount * imageWidth)) / textColsCount;
  }

  const isTextColumn = (col) => col.type == 'text' && filterIfUndersized(col);
  const filterIfUndersized = col => !(col.hideIfUndersized && width < MIN_COL_WIDTH_INCLUDE_ALL);
  const textColsCount = columns.filter(isTextColumn).length;
  const visibleCols = columns.filter(isTextColumn).concat(columns.filter(c => c.type != 'text'));

  const columnsWithWidths = columns => {
    return columns.filter(filterIfUndersized).map(col => {
      return {
        width: columnWidth(col, textColsCount),
        ...col
      }  
    });
  }

  const renderInfoBlock = ({ type, value, width }, key, isLastElement) => {
    switch(type) {
      case 'image':
        return (
          <div style={{ width }} className="flex items-center generic-columned-cell" key={key}> 
            <TiledImage key={key} tileImage={value} />
            {  !isLastElement && <Divider color="#1DE282" size="md" orientation="vertical" /> }
          </div>
        );
      case 'text':
        return (
          <div style={{ width }} className="flex items-center generic-columned-cell" key={key}> 
            <div className="px-2 overflow-hidden">{ value }</div>
            {  !isLastElement && <Divider color="#1DE282" size="md" orientation="vertical" /> }
          </div>
        );
      default:
        return;
    }
  }
  
  return (
    <Link to={link || '#'}>
      <div ref={componentRef} className="flex items-center hover:bg-slate-400 cursor-pointer">
      { columnsWithWidths(columns).map((col, index) => (
        renderInfoBlock(col, index, index == (visibleCols.length - 1))
      )) }
      </div>
    </Link>
  )
}

export default GenericColumnedCell;
