import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'
import { useQueryClient } from '@tanstack/react-query';

import { useAxios } from '../contexts/AxiosContext';

import { BorderedTitleContainer } from '../components/shared';
import MatchList from '../components/matches/matchList/MatchList';
import { ChampionPopularStreamers } from '../components/champions';

import { useChampionVideos, usePopularChampionStreamers } from '../services/ChampionApiService';

function ChampionVideosPage() {
  const { ref, inView } = useInView()
  const axios = useAxios();
  const queryClient = useQueryClient();
  let params = useParams();

  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    hasNextPage
  } = useChampionVideos(params.championId);
  const { data: popularStreamers, isLoading: isPopStreamersLoading } = usePopularChampionStreamers(params.championId);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div className="multiItemPageContainer">
      <ChampionPopularStreamers {...popularStreamers} isLoading={isPopStreamersLoading} />
      <BorderedTitleContainer title={'Videos'}>
        <MatchList
          data={data}
          fetchNextPage={fetchNextPage}
          isLoading={isFetching}
          loadingRef={ref}
          nextPageDisabled={!hasNextPage || isFetchingNextPage}
        />
      </BorderedTitleContainer>
    </div>
  )
}

export default ChampionVideosPage;
