import { StreamerForm } from '../components/streamers';

function StreamerCreationPage() {
  // TODO: REDIRECT IF NOT ADMIN
  return (
    <StreamerForm />
  )
}

export default StreamerCreationPage;
