import { useParams } from "react-router-dom";

import { useStreamer } from '../services/StreamerApiService';
import {
  BorderedTitleContainer,
  LoadingPlaceholder,
} from '../components/shared';
import {
  StreamerProfileInfo
} from '../components/streamers';
import { useUser } from '../services/UserApiService';

function StreamerPage() {
  let params = useParams();
  const { data: streamer, isLoading } = useStreamer(params.streamerId);
  const { data: user } = useUser();

  if (isLoading || !streamer) {
    return (
      <LoadingPlaceholder />
    )
  }

  return (
    <BorderedTitleContainer title={streamer.display_name}>
      <StreamerProfileInfo
        streamer={streamer}
        user={user}
      />
    </BorderedTitleContainer>
  )
}

export default StreamerPage;
