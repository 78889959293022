import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'
import { useQueryClient } from '@tanstack/react-query';

import { useAxios } from '../../../contexts/AxiosContext';

import { useStreamerMatches, streamerVideosFn, streamerVideosKey } from '../../../services/StreamerApiService';
import MatchList from '../../matches/matchList/MatchList';

function StreamerMatchesTab() {
  const { ref, inView } = useInView()
  const axios = useAxios();
  const queryClient = useQueryClient();
  let params = useParams();
  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
  } = useStreamerMatches(params.streamerId);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);
  
  useEffect(() => {
    queryClient.prefetchQuery(
      streamerVideosKey(params.streamerId),
      () => streamerVideosFn(axios, params.streamerId)
    );
  }, []);

  return (
    <MatchList
       data={data}
       fetchNextPage={fetchNextPage}
       isLoading={isFetching}
       loadingRef={ref}
       nextPageDisabled={!hasNextPage || isFetchingNextPage}
       showStreamer={false}
    />
  )
}

export default StreamerMatchesTab;
