import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'

import { useRecentMatches } from '../services/MatchApiService';

import MatchList from '../components/matches/matchList/MatchList';
import {
  BorderedTitleContainer,
  LoadingPlaceholder,
} from '../components/shared';

function HomePage() {
  const { ref, inView } = useInView()
  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
  } = useRecentMatches();
  
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div>
      <BorderedTitleContainer title={'Recent Matches'}>
        <MatchList
          data={data}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
          isLoading={isFetching}
          loadingRef={ref}
          nextPageDisabled={!hasNextPage || isFetchingNextPage}
        />
      </BorderedTitleContainer>
    </div>
  )
}

export default HomePage;
