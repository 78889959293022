import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

import { TiledImage } from '../../shared';

import VideoPlaySummary from './components/VideoPlaySummary';

function VideoListRow(props) {
   let navigate = useNavigate();

   const {
     duration,
     start_time,
     matches,
     streamer: { display_name, profile_image_url },
     showStreamer,
   } = props;

   return (
    <tr>
      { showStreamer &&
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <TiledImage tileImage={profile_image_url} height={'3rem'} width={'3rem'} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{display_name}</div>
          </div>
        </div>
      </td>
      }
      <td className="px-6 py-4 whitespace-nowrap text-sm text-black w-20">
        { new Date(start_time * 1000).toLocaleDateString("en-US") }
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-black w-20">
        { duration }
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
        <VideoPlaySummary height={'4rem'} video={props} />
      </td>
    </tr>
   )
}

export default VideoListRow;
