import { Component } from 'react';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';

import { TiledImage } from '../../../shared';
import { convertRemToPixels } from '../../../../services/Measurements';

const PADDING_WIDTH = 0.25;
const IMAGE_WIDTH = 3; 

const imageWidthNeeded = convertRemToPixels(IMAGE_WIDTH + (2 * PADDING_WIDTH));

function renderImage(tileImage, width) {
  if (width > imageWidthNeeded) {
    return <TiledImage tileImage={tileImage} height={`${IMAGE_WIDTH}rem`} width={`${IMAGE_WIDTH}rem`} />
  }
}

function calculateTooltip(tileImage, width) {
  if (width <= imageWidthNeeded) {
    return <TiledImage tileImage={tileImage} height={`${IMAGE_WIDTH}rem`} width={`${IMAGE_WIDTH}rem`} />
  } 

  // React should ignore falsy attributes
  return false;
}

function VideoPlaySummaryMatch(props) {
  let navigate = useNavigate();
  const { height, left, matchId, tileImage, width } = props;
  
  const renderMatchSummary = ({ height, left, matchId, tileImage, width }) => {
    return (
      <div
        onClick={() => navigate(`/matches/${matchId}`)}
        className="bg-primgreen absolute flex items-center justify-center cursor-pointer"
        style={{
          height,
          left,
          top: 0,
          width
        }}
        title={calculateTooltip(tileImage, width)}
      >
        { renderImage(tileImage, width) }
      </div>
    );
  }
  
  if (width <= imageWidthNeeded) {
    return (
      <Tooltip title={calculateTooltip(tileImage, width)} arrow>
        { renderMatchSummary(props) }
      </Tooltip>
    );
  }

  return renderMatchSummary(props);
}

export default VideoPlaySummaryMatch;
