import { useUserFollows } from './../../../services/UserApiService';
import {
  LoadingPlaceholder
} from '../../shared';
import { StreamerList } from '../../streamers';
import { STREAMER_NAME_IMAGE } from '../../streamers/constants';

function ProfileFollowsTab() {
  const { data: streamers, isLoading } = useUserFollows(); 
  if (isLoading || !streamers || !streamers.length) {
    return (
      <LoadingPlaceholder />
    )
  }

  return (
    <StreamerList
      columns={[STREAMER_NAME_IMAGE]}
      streamers={streamers}
      isLoading={isLoading}
    />
  )
}

export default ProfileFollowsTab;
