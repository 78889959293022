import { Component } from 'react';

import { PercentageBarDisplay, TiledImage } from '../../shared';

class StreamerChampPlayRow extends Component {
  render() {
    const { champion_tile_image, percent } = this.props;

    return (
      <div className="flex items-center w-full">
        <div className="p-1">
          <TiledImage tileImage={champion_tile_image}/>
        </div>
        <div className="w-16 p-1 text-center">{ `${Math.round(percent * 100)}%` }</div>
        <div className="w-full p-1">
          <PercentageBarDisplay height={'2rem'} percentage={percent} />
        </div>
      </div>
    )
  }
}

export default StreamerChampPlayRow;
