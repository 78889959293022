import { Routes, Route, Link } from "react-router-dom";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { MantineProvider, createTheme } from '@mantine/core';

import logo from './logo.svg';
import './App.css';

import {
  PrimaryLayout,
  ScreenSizeMaxLayout
} from './layouts';

import ChampionsPage from './pages/ChampionsPage';
import ChampionStreamerVideosPage from './pages/ChampionStreamerVideosPage';
import ChampionVideosPage from './pages/ChampionVideosPage';
import HomePage from './pages/HomePage';
import ProfilePage from './pages/ProfilePage';
import StreamerPage from './pages/StreamerPage';
import StreamerCreationPage from './pages/StreamerCreationPage';
import StreamersPage from './pages/StreamersPage';
import VideoPage from './pages/VideoPage';

import {
  StreamerAdminTab,
  StreamerMatchesTab,
  StreamerVideosTab
} from './components/streamers';
import {
  ProfileFollowsTab
} from './components/user';

import CallbackPopupComponent from './components/auth/CallbackPopupComponent';
import LoginComponent from './components/auth/LoginComponent';

import { AxiosProvider } from './contexts/AxiosContext';
const theme = createTheme({
  /** Put your mantine theme override here */
});


const queryClient = new QueryClient()

function App() {
  return (
    <MantineProvider>
      <QueryClientProvider client={queryClient}>
        <AxiosProvider>
          <Routes>
            <Route path="auth/callback" element={<CallbackPopupComponent />} />
            <Route path="/" element={<PrimaryLayout />}>
              <Route index element={<HomePage />} />
              <Route path="/" element={<ScreenSizeMaxLayout />}>
                <Route path="login" element={<LoginComponent />} />
              </Route>
              <Route path="profile" element={<ProfilePage />}>
                <Route index element={<ProfileFollowsTab />} />
                <Route path="streamer_creation" element={<StreamerCreationPage />} />
              </Route>
              <Route path="champions" element={<ChampionsPage />} />
              <Route path="champions/:championId" element={<ChampionVideosPage />} />
              <Route path="champions/:championId/streamers/:streamerId" element={<ChampionStreamerVideosPage />} />
              <Route path="streamers" element={<StreamersPage />} />
              <Route path="/" element={<ScreenSizeMaxLayout />}>
                <Route path="matches/:matchId" element={<VideoPage />} />
              </Route>
              <Route path="streamers/:streamerId" element={<StreamerPage />}>
                <Route index element={<StreamerMatchesTab />}/>
                <Route path="vods" element={<StreamerVideosTab />} />
                <Route path="admin" element={<StreamerAdminTab />} />
              </Route>
            </Route>
          </Routes>
        </AxiosProvider>
      <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </MantineProvider>
  );
}

export default App;
