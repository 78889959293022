import { Component } from 'react';

import {
  GenericTable
} from '../../shared';

import SummonerListRow from '../summonerListRow/SummonerListRow';

class SummonerList extends Component {
  render() {
    const { isLoading, summoners } = this.props;

    if (isLoading || !summoners || !summoners.length) {
      return <h1>Loading!</h1>
    }

    return (
      <GenericTable columns={['Summoner Name']}>
        {
          summoners.map(summoner => (
            <SummonerListRow {...summoner} key={summoner.puuid} />
          ))
        }
      </GenericTable>
    )
  }
}
export default SummonerList;
