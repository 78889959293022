import { Component } from 'react';

import {
  GenericTable
} from '../../shared';
import StreamerListRow from '../streamerListRow/StreamerListRow';
import { COLUMN_TO_NAME, DEFAULT_COLUMNS } from '../constants';

class StreamerList extends Component {
  render() {
    const { columns, streamers, isLoading } = this.props;

    if (isLoading || !streamers || !streamers.length) {
      return <h1>Loading!</h1>
    }

    const columnNames = (columns || DEFAULT_COLUMNS).map(c  => COLUMN_TO_NAME[c]);

    return (
      <GenericTable columns={columnNames}>
          {
            streamers.map(streamer => (
              <StreamerListRow columns={columns || DEFAULT_COLUMNS} {...streamer} key={streamer.id} />
            ))
          }
      </GenericTable>
    );
  }
}

export default StreamerList;
