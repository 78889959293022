import {
  useInfiniteQuery,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useAxios } from '../contexts/AxiosContext';

export function useSidebar() {
  const axios = useAxios();

  return useQuery(["sidebar"], async () => {
    const { data } = await axios.get('/api/sidebar');

    return data;
  });
}

export function useStreamers() {
  const axios = useAxios();

  return useQuery(["streamers"], async () => {
    const { data } = await axios.get('/api/streamers');

    return data;
  });
}

export function useStreamer(streamerId) {
  const axios = useAxios();

  return useQuery(["streamers", streamerId], async () => {
    const { data } = await axios.get(`/api/streamers/${streamerId}`);

    return data;
  });
}

export function useStreamerMatches(streamerId) {
  const axios = useAxios();

  return useInfiniteQuery(
    streamerMatchesKey(streamerId),
    async ({ pageParam = 1 }) => streamerMatchesFn(axios, streamerId, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
    }
  );
}

export function streamerMatchesKey(streamerId) {
  return ["streamers", streamerId, "matches"];
}

export async function streamerMatchesFn(axios, streamerId, pageParam) {
    const { data } = await axios.get(`/api/streamers/${streamerId}/matches?page=${pageParam}`);

    return data;
}

export function useStreamerVideos(streamerId) {
  const axios = useAxios();

  return useQuery(streamerVideosKey(streamerId), () => streamerVideosFn(axios, streamerId));
}

export function streamerVideosKey(streamerId) {
  return ["streamers", streamerId, "videos"];
}

export async function streamerVideosFn(axios, streamerId) {
    const { data } = await axios.get(`/api/streamers/${streamerId}/twitch_videos`);

    return data;
}

export function createStreamerSummoner(axios, twitch_link, op_link) {
  return axios.post('/api/streamers',
    { twitch_link, op_link },
    {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
}

export function syncUserVideos(axios, streamerId) {
  return axios.post(`/api/streamers/${streamerId}/sync`);
}
