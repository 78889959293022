import {
  useInfiniteQuery,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { useAxios } from '../contexts/AxiosContext';

export function useMatch(matchId) {
  const axios = useAxios();

  return useQuery(["match", matchId], async () => {
    const { data } = await axios.get(`/api/matches/${matchId}`);

    return data;
  });
}

export function markAsViewed(axios, matchId) {
  return axios.post(`/api/matches/${matchId}/views`, {
      body: JSON.stringify({})
    });
}

export function useRecentMatches() {
  const axios = useAxios();

  return useInfiniteQuery(
    ["match", "recent"],
    async ({ pageParam = 1 }) => {
      const { data } = await axios.get(`/api/matches/recent?page=${pageParam}`);
      return data;
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
    }
  );
}

