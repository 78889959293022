import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useAxios } from '../contexts/AxiosContext';

import { initUser, logout, setUser } from '../services/UserAuthService';

export function useUser() {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useQuery(["user"], async () => {
    const resp = await axios.get('/api/auth/validate_token');
    const { data } = resp.data;

    return data;
  }, {
    initialData: initUser,
    onSuccess: async(data) => {
      if (data) {
        return setUser(data);
      }
    },
    onError: async (err) => {
      console.log('LOGOUT', err);
      await logout();
      queryClient.setQueryData(['user'], () => null);
      queryClient.resetQueries({ queryKey: ['sidebar'] });
    },
    retry: false
  });
}

export function useUserFollows() {
  const axios = useAxios();

  return useQuery(["follows"], async () => {
    const resp = await axios.get('/api/streamers/following');
    const { data } = resp;

    return data;
  });
}

export function updateFollows(axios) {
  return axios.post(`/api/streamers/update_follows`, {
    method: 'POST',
    body: JSON.stringify({})
  });
}
