import { Button } from '@mantine/core';

import { useNavigate, useParams } from 'react-router-dom';

import { useAxios } from '../../../contexts/AxiosContext';
import { useStreamer } from '../../../services/StreamerApiService';
import { useUser } from '../../../services/UserApiService';

import { syncUserVideos } from '../../../services/StreamerApiService';

import StreamerAdminControls from './components/StreamerAdminControls';

function StreamerAdminTab() {
  const navigate = useNavigate();
  const { data: user, isLoading } = useUser();

  let params = useParams();

  const { data: streamer, isLoading: isStreamerLoading } = useStreamer(params.streamerId);
  if (isLoading || isStreamerLoading) {
    return;
  }

  if (!isLoading && user && !user.admin) {
    navigate("/");
  }

  return (
    <div className="m-2">
      <StreamerAdminControls streamer={streamer} />
    </div>
  )
}

export default StreamerAdminTab;
