import { Component } from 'react';
import { Link } from 'react-router-dom';

import { TiledImage } from '../../components/shared';

class StreamerNavListItem extends Component {
  render() {
    const { streamer: { display_name, id, profile_image_url } } = this.props;
    return (
      <Link to={`/streamers/${id}`}>
        <li className="px-4 flex items-center hover:bg-slate-400">
          <TiledImage tileImage={profile_image_url} width={'2rem'} height={'2rem'} /> 
          <span className="px-2">{ display_name }</span>
        </li>
      </Link>
    )
  }
}
export default StreamerNavListItem;
