import { Component } from 'react';

import './TiledImage.css';

class TiledImage extends Component {
  render() {
    const { disabled, tileImage, height = '4rem', onClick = null, width = '4rem' } = this.props;

    const style = {
      height,
      width,
      minWidth: width
    };

    if (disabled) {
      style.filter = 'grayscale(100%)';
    }

    return (
      <img style={style} className={`float-left m-1 rounded-md border-stone-400 drop-shadow-lg ${onClick ? 'image-hover-highlight' : ''}`} src={tileImage} onClick={onClick}/>
    )
  }
}

export default TiledImage;
