export const getNextPageParamConfig = {
  getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
}

export const useInfiniteQueryPaginated = (axios, url) => async({ pageParam = 1 }) => {
  const { data } = await axios.get(url + `?page=${pageParam}`);
  console.log('DATA: ', data)

  return data;
}
