import { Component } from 'react';
import { Link } from 'react-router-dom';

import { Group } from '@mantine/core';

import UserProfileNav from './userProfileNav/UserProfileNav';

class Nav extends Component {
  render() {
    const navigationLinks = [
      { name: 'Home', href: '/' },
      { name: 'Champions', href: '/champions' },
      { name: 'Streamers', href: '/streamers' }
    ];

    return (
      <Group justify="space-between" style={{ height: '100%' }} className="px-2 bg-primblue">
         <div className="flex items-center text-white">
            <img src="/logo.png" style={{ height: '3rem', width: '3rem' }}/>
         {
             navigationLinks.map(({ name, href }, index) => (
             <Link className="px-2" key={index} to={href}>{name}</Link>
             ))
         }
         </div>
         <div>
           <UserProfileNav />
         </div>
      </Group>
    )

    return (
      <nav>
        <div className="bg-primblue mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="text-white">
            {
              navigationLinks.map(({ name, href }, index) => (
              <Link className="px-2" key={index} to={href}>{name}</Link>
              ))
            }
            </div>
            <div>
              <UserProfileNav />
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Nav;
