import { useParams } from "react-router-dom";
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer'

import { useChampionStreamerVideos } from '../services/ChampionApiService';

import { BorderedTitleContainer } from '../components/shared';
import MatchList from '../components/matches/matchList/MatchList';

function ChampionStreamerVideosPage() {
  const { ref, inView } = useInView()
  let params = useParams();
  const {
    data,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    hasNextPage
  } = useChampionStreamerVideos(params.championId, params.streamerId);
  
  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div>
      <BorderedTitleContainer title={'Videos'}>
        <MatchList
          data={data}
          fetchNextPage={fetchNextPage}
          isLoading={isFetching}
          loadingRef={ref}
          nextPageDisabled={!hasNextPage || isFetchingNextPage}
          showStreamer={false}
        />
      </BorderedTitleContainer>
    </div>
  )
}

export default ChampionStreamerVideosPage;
