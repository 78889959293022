import './LoadingPlaceholder.css';

function LoadingPlaceholder() {
  return (
    <div className="flex items-center justify-center space-x-2 w-full h-full">
      <div className="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"></div>
    </div>
  )
}

export default LoadingPlaceholder;
