import { TiledImage } from '../../shared';

function StreamerNameImageCell(props) {
  const { display_name, profile_image_url } = props;

  return (
    <td className="px-6 py-4 whitespace-nowrap">
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <TiledImage tileImage={profile_image_url} height={'3rem'} width={'3rem'} />
        </div>
        <div className="ml-4">
          <div className="text-sm font-medium text-gray-900">{display_name}</div>
        </div>
      </div>
    </td>
  )
}

export default StreamerNameImageCell;
