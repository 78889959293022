import { Component } from 'react';

class MatchMetadata extends Component {
  render() {
    const { assists, deaths, kills } = this.props;

    return (
      <div>
        { kills }k-{ assists }a-{ deaths }d
      </div>
    )
  }
}

export default MatchMetadata;
