import { Button } from '@mantine/core';
import { useAxios } from '../../../../contexts/AxiosContext';
import { syncUserVideos } from '../../../../services/StreamerApiService';

import { useSummoners } from '../../../../services/SummonerApiService';

import { SummonerList } from '../../../summoners';

function StreamerAdminControls(props) {
  const axios = useAxios();
  const { streamer: {  id, last_updated: lastUpdated } } = props;

  const { data: summoners, isLoading } = useSummoners(id);

  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="text-lg">Last updated: { lastUpdated }</div>
        <div>
          <Button
            onClick={async() => syncUserVideos(axios, id)}>
            Sync User
          </Button>
        </div>
      </div>
      <SummonerList isLoading={isLoading} summoners={summoners} />
    </div>
  )
}
export default StreamerAdminControls;
