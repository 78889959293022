import { Component } from 'react';

class GenericTable extends Component {
  renderHeaderCell(column) {
    return (
        <th
          key={column}
          scope="col"
          className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider">{ column }</th>

    );
  }

  render() {
    const { columns, children } = this.props;

    return (
      <table className="table-fixed min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {
              columns.map(column => this.renderHeaderCell(column))
            }      
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          { children }
        </tbody>
      </table>
    )
  }
}
export default GenericTable;
