import { Component } from 'react';

import TabLink from './components/TabLink';

class TabbedContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: (props.activeTab || props.tabs[0].label) };
  }

  isActiveTab(tab) {
    const { activeTab } = this.state;

    return tab === activeTab;
  }

  setActiveTab = tab => {
    this.setState({ activeTab: tab });
  }

  renderTabs() {
    const { activeTab } = this.state;
    const { tabs } = this.props;

    if (!tabs || !tabs.length) {
      return;
    }
    
    return (
      <div className="flex h-8 border-b-4 border-primgreen w-full">
        { tabs.map((tab, index) => (
          <TabLink key={index} {...tab} isActiveTab={this.isActiveTab(tab.label)} onClickTab={this.setActiveTab} />
        )) }
      </div>
    )
  }

  render() {
    const { children, tabs } = this.props;

    return (
      <div>
        { this.renderTabs(tabs) }
        { children }
      </div>
    )
  }
}

export default TabbedContainer;
