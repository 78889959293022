import { useState, useRef } from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { useResize } from '../services/ElementSizeService';


function ScreenSizeMaxLayout() {
  const componentRef = useRef();
  const { height } = useResize(componentRef);

  return (
    <div style={{ 'height': 'calc(100dvh - 76px - 1rem)' }} ref={componentRef}>
      <Outlet context={{ containerHeight: height }}/>
    </div>
  )
};

export function useContainerHeight() {
  return useOutletContext()
}

export default ScreenSizeMaxLayout;
