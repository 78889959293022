import { Component } from 'react';

class CallbackPopupComponent extends Component {
  componentDidMount() {
    const extractCredentials = () => {
      const url = new URL(window.location.href);
      const authData = url.search.replace("?","").split("&").map(i => i.split('='));
      return authData.reduce((obj, entry) => {
        obj[entry[0]] = entry[1];
        return obj;
      }, {});
    }

    window.addEventListener("message", function(ev) {
      if (ev.data === "requestCredentials") {
        const data = extractCredentials();
        ev.source.postMessage(data, '*');
        window.close();
      }
    });
  }

  render() {
    return <div>Redirecting</div>;
  }
}

export default CallbackPopupComponent;
