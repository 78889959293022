import { Component } from 'react';

import { BorderedTitleContainer, GenericCellGrid } from '../../shared';

class ChampionPopularStreamers extends Component {
  render() {
    const { data, isLoading, title } = this.props;

    if (isLoading || !data || !data.length) {
      return <div></div>;
    }

    return (
      <BorderedTitleContainer title={title}>
        <GenericCellGrid data={data} />
      </BorderedTitleContainer>
    )
  }
}

export default ChampionPopularStreamers;
