import { Component } from 'react';

import { SimpleGrid } from '@mantine/core';

import GenericColumnedCell from '../genericColumnedCell/GenericColumnedCell.js';

class GenericCellGrid extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="py-2">
        <SimpleGrid cols={{ base: 1, md: 2 }}>
          { data.map((row, index) => (
            <GenericColumnedCell {...row} key={index} />
          )) }
        </SimpleGrid>
      </div>
    )

    return (
      <div className="grid grid-cols-2 w-full m-4 divide-x divide-gray-200">
        { data.map((row, index) => (
          <GenericColumnedCell {...row} key={index} />
        )) }
        { (data.length % 2 == 1) && 
          <div></div>
        }
      </div>
    )
  }
}

export default GenericCellGrid;

