import { useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import { AppShell, Burger, Group, Skeleton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { Nav, Sidebar } from './components';
import StreamerNavList from './components/StreamerNavList';

import { useSidebar } from '../services/StreamerApiService';
import { useUser } from '../services/UserApiService';

function PrimaryLayout() {
  useUser();
  const { data: streamers, isLoading } = useSidebar();
  const [opened, { toggle }] = useDisclosure();

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header>
        <Nav />
      </AppShell.Header>
      <AppShell.Navbar>
        <StreamerNavList isLoading={isLoading} streamers={streamers} />
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  )
}

export default PrimaryLayout;
