import { Component } from 'react';

import StreamerChampPlayRow from '../streamerChampPlayRow/StreamerChampPlayRow';

class StreamerChampPlayRate extends Component {
  render() {
    const { champions } = this.props;

    return (
      <div className="flex flex-col">
        <div className="w-full">Most Streamed Champions</div>
      { champions.map(({ champion: { id }, percent_played, tile_image }) => (
        <StreamerChampPlayRow key={id} champion_tile_image={tile_image} percent={percent_played} />
      )) } 
      </div>
    )
  }
}

export default StreamerChampPlayRate;
