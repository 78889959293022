import { Component } from 'react';

import { TiledImage } from  '../../shared';

class StreamerMostPlayedCell extends Component {
  render() {
    const { mostPlayedChamps } = this.props;

    return (
      <div className="flex">
      { mostPlayedChamps.map(({ champion: { id }, tile_image }) => (
        <TiledImage key={id} height={'3rem'} width={'3rem'} tileImage={tile_image} />
      )) }
      </div>
    )
  }
}

export default StreamerMostPlayedCell;
