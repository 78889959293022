import { forwardRef, useContext, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';

import { Link } from 'react-router-dom';

import { Menu, Button, UnstyledButton } from '@mantine/core';

import { logout } from '../../../services/UserAuthService';
import { useUser } from '../../../services/UserApiService';

import { TiledImage } from '../../../components/shared';

import './UserProfileNav.css';

function UserProfileNav() {
  const queryClient = useQueryClient();
  const { data: user } = useUser();

  const [showDropdown, setShowDropdown] = useState(false);

  if (user && user.image) {
    return (
      <Menu position="bottom-end" trigger="click-hover" width={200}>
        <Menu.Target>
          <UnstyledButton>
            <TiledImage tileImage={user.image} height={'3rem'} width={'3rem'} />
          </UnstyledButton>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item component={Link} to={'/profile'}>Profile</Menu.Item>
          <Menu.Item onClick={async() => {
            await logout();
            setShowDropdown(false);
            await queryClient.resetQueries();
          }}>Logout</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    )
  } else {
    return <Button component={Link} to={'/login'} variant="filled" color="#1DE282">Login/Signup</Button>
  }
}

export default UserProfileNav;
