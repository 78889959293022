import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

import { TiledImage } from '../../shared';
import MatchMetadata from './components/MatchMetadata';

import './MatchListRow.css';

function MatchListRow(props) {
   let navigate = useNavigate();

   const {
     champion: { name: champion_name, tile_image },
     game_start_time,
     id,
     is_viewed,
     keystone_id,
     metadata,
     opposing_champion: { name: opposing_champion_name },
     role,
     showStreamer,
     streamer: { display_name, profile_image_url },
     summoner
   } = props;

   return (
    <tr onClick={() => navigate(`/matches/${id}`)} className={`hover:bg-slate-400 ${is_viewed ? 'is-viewed' : ''}`}>
      { showStreamer &&
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <TiledImage tileImage={profile_image_url} height={'3rem'} width={'3rem'} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900">{display_name}</div>
            <div className="text-sm text-black">{ summoner.name }</div>
          </div>
        </div>
      </td>
      }
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <TiledImage tileImage={tile_image} height={'3rem'} width={'3rem'}/>
          </div>
          <div className="ml-4">
            <div className="inline">
              <TiledImage tileImage={`/assets/positions/${role.toLowerCase()}.png`} height={'1rem'} width={'1rem'} />
              <div className="text-sm text-gray-900">{ champion_name }</div>
            </div>
            <div className="text-sm text-black">vs. { opposing_champion_name }</div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
        { new Date(game_start_time * 1000).toLocaleDateString("en-US") }
      </td>
      <td className="px-6 py-4 whitespace-nowrap">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <TiledImage tileImage={`/assets/runes/${keystone_id}.png`} height={'3rem'} width={'3rem'} />
          </div>
          <div className="ml-4">
            <div className="text-sm font-medium text-gray-900"></div>
            <div className="text-sm text-black"></div>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 whitespace-nowrap text-sm text-black">
        <MatchMetadata {...metadata} />
     </td>
    </tr>
   )
}

export default MatchListRow;
