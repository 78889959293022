import Axios from 'axios';
import { createContext, useContext, useMemo } from 'react';

import { getAuthHeaders, setAuthDataFromHeaders } from '../services/UserAuthService';

export const AxiosContext = createContext();

export function AxiosProvider({
  children,
}) {
  const axios = useMemo(() => {
    const axios = Axios.create({
      headers: {
        "Content-Type": "application/json",
      },
    });

    axios.interceptors.request.use(async (config) => {
      const authHeaders = await getAuthHeaders();
      const baseHeaders = config.headers;

      config.headers = {
        ...baseHeaders,
        ...authHeaders
      }

      return config;
    });

    axios.interceptors.response.use((response) => {
      // TODO: if this is the validation endpoint I think it needs to do it from the body
      return setAuthDataFromHeaders(response);
    }, err => {
      console.log('AXIOS ERR: ', err);
      return Promise.reject(err);
    });

    return axios;
  }, []);

  return (
    <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
  );
}

export function useAxios() {
  return useContext(AxiosContext);
}
