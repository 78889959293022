import { useStreamers } from '../services/StreamerApiService';
import {
  BorderedTitleContainer,
  LoadingPlaceholder,
} from '../components/shared';
import {
  StreamerList
} from '../components/streamers';

function StreamersPage() {
  const { data: streamers, isLoading } = useStreamers();

  if (isLoading || !streamers || !streamers.length) {
    return (
      <LoadingPlaceholder />
    )
  }

  return (
    <BorderedTitleContainer title={'Streamers'}>
      <StreamerList
        streamers={streamers}
        isLoading={isLoading}
      />
    </BorderedTitleContainer>
  )
}

export default StreamersPage;
