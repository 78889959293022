import { Component } from 'react';
import { Button } from '@mantine/core';

import MatchListRow from '../matchListRow/MatchListRow';

class MatchList extends Component {
  render() {
    const { data, fetchNextPage, loadingRef, isLoading, nextPageDisabled, showStreamer = true } = this.props;

    if (isLoading && !data) {
      return <h1>Loading!</h1>
    }

    return (
      <>
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
              <tr>
                { showStreamer &&
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Streamer
                </th>
                }
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Playing
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Date
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Build
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-black uppercase tracking-wider"
                >
                  Stats
                </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {
            (data.pages || [data]).flatMap(({ data }) => data).map((match) => (
              <MatchListRow
                {...match}
                id={match.id}
                key={match.id}
                showStreamer={showStreamer} />
            ))
          }
        </tbody>
      </table>
      <Button fullWidth variant="filled" disabled={nextPageDisabled} onClick={fetchNextPage} ref={loadingRef}>Load More Matches</Button>
    </>      
    )
  }
}

export default MatchList;
