import { useChampions } from '../services/ChampionApiService';

import ChampSelector from '../components/champions/champSelector/ChampSelector';

function ChampionsPage() {
  const { data, isLoading } = useChampions();

  return (
    <div>
      <ChampSelector champions={data} isLoading={isLoading} />
    </div>
  )
}

export default ChampionsPage;
