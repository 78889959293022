export const STREAMER_NAME_IMAGE = 'streamer';
export const MOST_PLAYED_ROLE = 'most_played_role';
export const MOST_PLAYED_CHAMP = 'most_played_champ';

export const DEFAULT_COLUMNS = [
  STREAMER_NAME_IMAGE,
  MOST_PLAYED_ROLE,
  MOST_PLAYED_CHAMP
];

export const COLUMN_TO_NAME = {
  [STREAMER_NAME_IMAGE]: 'Streamer',
  [MOST_PLAYED_ROLE]: 'Most Played Role',
  [MOST_PLAYED_CHAMP]: 'Most Played Champions'
};
